import { createStore } from 'vuex'
import Ls from '../utils/token'
import common from '../assets/js/common'

export default createStore({
    state: { // 此处为公共变量
      userId: Ls.getParseToken('nof_userInfo').userId, // 用户Id
      userName: Ls.getParseToken('nof_userInfo').username, // 用户Id
      roleId: Ls.getParseToken('nof_userInfo').roleId,
      userInfo: Ls.getParseToken('nof_userInfo') || {},
      tokenData: JSON.parse(localStorage.getItem('tokenData')) || {}, //对接qiniu的信息
      //统一的错误提示
      error:{
        overdue:"已过期"
      }
    },
    mutations: { // 此处为同步方法

      SET_USER_ID (state, userId) { // 存入state中的用户凭证
        state.userId = userId
      },
      setUserInfo (state, info) {
        state.userInfo = info

        state.userId= info.userId;
        state.userName= info.username;
        state.roleId=info.roleId;

        Ls.setToken('nof_userInfo', JSON.stringify(info))
      },
      setUserName (state,val){
        state.username = val
        let userInfo = Ls.getParseToken('nof_userInfo')
        userInfo.username = val
        Ls.setToken('nof_userInfo', JSON.stringify(userInfo))
      },
      setUserItem (state,val){
        if(val.type === 'username'){
          state.username = val.data
        }

        let userTmpInfo = Ls.getParseToken('nof_userInfo')
        userTmpInfo[val.type] = val.data
        Ls.setToken('nof_userInfo', JSON.stringify(userTmpInfo))
      }

    },
    actions: {},
    modules: {// 此处为混入更多的vuex小模块

    },
    gatters: { // 此处为计算变量
      isLogin () {
        return !!((this.userId && this.loginSession))
      }
    }
  }
)

