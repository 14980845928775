import emitter from '../../utils/bus';
export default {
  name: "LeftBar",
  props: ['itemName'],
  data: function data() {
    return {
      isCollapse: false,
      tmpMenus: [],
      menus: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.tmpMenus = this.$router.options.routes;
    for (var i = 0; i < this.tmpMenus.length; i++) {
      if (this.tmpMenus[i].meta && this.tmpMenus[i].meta.title === this.itemName) {
        this.menus = this.tmpMenus[i].children;
        break;
      }
    }
    emitter.on("ToggleFun", function (res) {
      _this.isCollapse = res;
    });
  },
  beforeCreate: function beforeCreate() {
    emitter.off("ToggleFun");
  },
  computed: {
    activeMenu1: function activeMenu1() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      if (meta.nowMenu) {
        return meta.nowMenu;
      }
      return path;
    }
  },
  methods: {
    ToggleFun: function ToggleFun(val) {
      this.isCollapse = val;
    },
    handleSelect: function handleSelect(key, keyPath) {
      this.$router.push({
        path: key
      });
    },
    handleOpen: function handleOpen(key, keyPath) {},
    handleClose: function handleClose(key, keyPath) {}
  }
};