import { ref, onMounted } from 'vue';
export default {
  name: 'ToastS',
  data: function data() {
    return {};
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  setup: function setup() {
    var visible = ref(false);
    onMounted(function () {
      visible.value = true;
    });
    return {
      visible: visible
    };
  }
};