export default {
  name: "RightMain",
  data: function data() {
    return {
      numList: [{
        id: 1,
        title: '课程资源',
        number: '0',
        lastMonth: '0',
        rightImg: require('../../assets/img/index/data-image-1.png'),
        bgColor: '#F1F5FF'
      }, {
        id: 2,
        title: '机构数量',
        number: '0',
        lastMonth: '0',
        rightImg: require('../../assets/img/index/data-image-2.png'),
        bgColor: '#F7F1FF'
      }, {
        id: 3,
        title: '用户数量',
        number: '0',
        lastMonth: '0',
        rightImg: require('../../assets/img/index/data-image-3.png'),
        bgColor: '#F1F5FF'
      }, {
        id: 4,
        title: '授权数量',
        number: '0',
        lastMonth: '0',
        rightImg: require('../../assets/img/index/data-image-4.png'),
        bgColor: '#FFF2F1'
      }],
      functionList: [{
        id: 1,
        name: '课程管理',
        img: require('../../assets/img/index/function-image-1.png'),
        path: '/course'
      },
      // {
      //   id:2,
      //   name:'资源管理',
      //   img:require('../../assets/img/index/function-image-2.png'),
      //   path:'/course/resourcetype'
      // },
      {
        id: 3,
        name: '用户管理',
        img: require('../../assets/img/index/function-image-3.png'),
        path: '/customer/user'
      }, {
        id: 4,
        name: '机构管理',
        img: require('../../assets/img/index/function-image-4.png'),
        path: '/CustomerCenter/organization'
      }]
    };
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.service.axiosUtils({
        requestName: 'getOrdinaryData',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            var info = res.data;
            _this.numList[0].number = info.Resource.TodayTotalCnt + '个';
            _this.numList[0].lastMonth = info.Resource.LastMonthCnt + '个';
            _this.numList[1].number = info.Organization.TodayTotalCnt + '家';
            _this.numList[1].lastMonth = info.Organization.LastMonthCnt + '家';
            _this.numList[2].number = info.User.TodayTotalCnt + '人';
            _this.numList[2].lastMonth = info.User.LastMonthCnt + '人';
            _this.numList[3].number = info.Token.TodayTotalCnt + '个';
            _this.numList[3].lastMonth = info.Token.LastMonthCnt + '个';
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    goWhere: function goWhere(v) {
      this.$router.push({
        path: v.path
      });
    }
  }
};