import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "up"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", {
        class: "toasts"
      }, _toDisplayString($props.text), 513), [[_vShow, $setup.visible]])];
    }),
    _: 1
  });
}