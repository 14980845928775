import { createVNode, render } from 'vue'
import ToastDom from './Toast.vue'
import ToastDomS from './ToastS.vue'

const div = document.createElement('div')
// div.setAttribute('class', 'toasts')
document.body.appendChild(div)

let time = null
export default {
  // 错误提示
  error: (text, duration = 3000) => {
    const NODE = createVNode(ToastDom, { text })
    render(NODE, div)
    clearTimeout(time)
    time = setTimeout(() => {
      render(null, div)
    }, duration)
  },
  success: (text, duration = 3000) => {
    const NODE = createVNode(ToastDomS, { text })
    render(NODE, div)
    clearTimeout(time)
    time = setTimeout(() => {
      render(null, div)
    }, duration)
  }
}

