import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1af7b00e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "submit-button button1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "submit-detail",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.onsubmit && $options.onsubmit.apply($options, arguments);
    })
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1)]);
}