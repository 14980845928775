import "core-js/modules/es.object.keys.js";
import emitter from '../../utils/bus';
export default {
  data: function data() {
    return {
      username: this.Ls.getParseToken('nof_userInfo').username,
      tipsState: false,
      menus: [],
      centerDialogVisible: false,
      fullscreen: false
    };
  },
  props: ['isHave'],
  created: function created() {
    this.menus = this.$router.options.routes;
  },
  computed: {
    // 我们使用计算属性来获取到当前点击的菜单的路由路径，然后设置default-active中的值
    // 使得菜单在载入时就能对应高亮
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    }
  },
  mounted: function mounted() {
    this.judegIsFullScreen();
    var _this = this;
    window.addEventListener("setItemEvent", function (e) {
      if (e.key === "nof_userInfo") {
        _this.username = JSON.parse(e.newValue).username;
      }
    });
  },
  methods: {
    judegIsFullScreen: function judegIsFullScreen() {
      var _this2 = this;
      window.onresize = function () {
        // 可视区域的高度
        var clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        // screen是window的属性方法，window.screen可省略window，指的是窗口
        _this2.fullscreen = screen.height === clientHeight;
      };
    },
    //主菜单跳转
    handleSelect: function handleSelect(key, keyPath) {
      if (this.$route.path.indexOf(key) < 0) {
        this.tipsState = false;
      }
      this.$router.push({
        path: key
      });
    },
    //左侧bar收起展开状态
    tipsToggle: function tipsToggle() {
      if (this.tipsState) {
        this.tipsState = false;
      } else {
        this.tipsState = true;
      }
      emitter.emit('ToggleFun', this.tipsState);
    },
    //退出显示弹框
    logout: function logout() {
      this.centerDialogVisible = true;
    },
    //弹框确定函数
    confirmFun: function confirmFun() {
      var _this3 = this;
      //Logout
      this.service.axiosUtils({
        requestName: 'Logout',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.centerDialogVisible = false;
            _this3.$toast.success(res.message);
            localStorage.clear();
            _this3.$router.push({
              path: '/login'
            });
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // 全屏事件
    full: function full() {
      var element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        // console.log('已还原！');
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
        // console.log('已全屏！');
      }
      // 改变当前全屏状态
      this.fullscreen = !this.fullscreen;
    },
    // 账户中心跳转
    goAccount: function goAccount() {
      this.$router.push({
        path: '/account'
      });
    }
  }
};