import service from './service'
import * as qiniu from 'qiniu-js'
const qiniuConfig = {
    "expires":7200000 //有效期,单位毫秒
};
import { ElLoading } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
//为了兼容封面渲染的时候渲染多条，调用多次token，上一个还没请求过来，下一个就又发出去请求了的问题
var isFlag=false;
export var qn_token_get =()=>{
  return new Promise(function(resolve, reject){
    if(isFlag)return;
    isFlag=true;
    //判断时间戳是否过期
    let oldTime=localStorage.getItem("tokenTime") || 0;
    let newTime=new Date().getTime();
    let result=newTime - oldTime;
    if(result > (qiniuConfig.expires - 2000)){
      // console.log("开始获取token")
      // 要做的事情...
      service.axiosUtils({
        requestName: 'getQiniuToken',
        data: {
          // "orgId":2,
          "expires":qiniuConfig.expires
        },
        sucFn: res => {
          oldTime=new Date().getTime();
          localStorage.setItem("tokenTime",oldTime)
          localStorage.setItem("tokenData",JSON.stringify(res.data))
          isFlag=false;
          resolve(res.data);
        },
        errFn:err=>{
          isFlag=false;
          reject(err);
        }
      })
    }else{
      isFlag=false;
      resolve(JSON.parse(localStorage.getItem("tokenData")));
    }

  });
}
var loading=null;
//上传图片到qiniu服务器并且返回地址
export var uploadFile = (json,file) =>{
  if(!json.isHideLoading){
    window.loading=ElLoading.service({
      lock:true,
      text:"正在上传，请稍后...",
      background:'rgba(0,0,0,0.3)'
    })
  }
  // duration
  qn_token_get().then((tokenData)=>{
    var folderName=json.folderName;
    var duration=json.duration || 0;//时长
    var index=json.index != undefined ? json.index : -1;//多个的时候的下标
    // 当前VUE中的this 是指向实例，相当于父级，指向指不到子级中。所需需要一个变量 _this 存储this得指向。
    let _this = this
    // 获取身份的token
    let token = tokenData.token;
    var dateTime=json.dateTime || new Date().getTime();
    var key=folderName+"/"+dateTime+"/"
    if(file.webkitRelativePath){
      key=key+file.webkitRelativePath;
    }else{
      key=key+file.name;
    }

    // 上传时的配置
    var config = {
      useCdnDomain: true,
      // region:_this.tokenData.region
    };
    //  设置文件的配置
    var putExtra = {
      fname: "",
      params: {},
      mimeType: null
    };


    // console.log(file, key, token, putExtra, config)
    //   实例化七牛云的上传实例
    var observable = qiniu.upload(file, key, token, putExtra, config);
    //   设置实例的监听对象
    var observer = {
      //   接收上传进度信息
      next(res) {
        // 上传进度
        /*_this.filePercent = parseInt(res.total.percent)
        if(_this.filePercent==100){
          alert("上传成功")
        }*/
        json.next && json.next(res,subscription)
      },
      // 接收上传错误信息
      error(err) {
        // console.log(err)
        json.error && json.error(err);
      },

      // 接收上传完成后的信息
      complete(res) {
        let url = tokenData.domain+"/"+res.key;
        res.size=file.size;
        res.name=file.name;
        res.duration=duration; //时长也一起返回回去
        res.index=index; //多个文件时候的下标
        json.complete && json.complete(res,url);
      }
    };
    // 上传开始
    var subscription = observable.subscribe(observer);

    // 上传取消
    // subscription.unsubscribe();
  })

};
//数组
/*
export var uploadFileList  = (json,fileList) =>{
  qn_token_get().then((tokenData)=>{
    var folderName=json.folderName;
    // 当前VUE中的this 是指向实例，相当于父级，指向指不到子级中。所需需要一个变量 _this 存储this得指向。
    let _this = this
    // 获取身份的token
    let token = tokenData.token;
    var dateTime=new Date().getTime();
    var key=folderName+"/"+dateTime+"/"+file.name;

    // 上传时的配置
    var config = {
      useCdnDomain: true,
      // region:_this.tokenData.region
    };
    //  设置文件的配置
    var putExtra = {
      fname: "",
      params: {},
      mimeType: null
    };


    // console.log(file, key, token, putExtra, config)
    //   实例化七牛云的上传实例
    var observable = qiniu.upload(file, key, token, putExtra, config);
    //   设置实例的监听对象
    var observer = {
      //   接收上传进度信息
      next(res) {
        // 上传进度
        /!*_this.filePercent = parseInt(res.total.percent)
        if(_this.filePercent==100){
          alert("上传成功")
        }*!/
        json.next && json.next(res)
      },
      // 接收上传错误信息
      error(err) {
        // console.log(err)
        json.error && json.error(err);
      },

      // 接收上传完成后的信息
      complete(res) {
        let url = tokenData.domain+"/"+res.key;
        json.complete && json.complete(res,url);
      }
    };
    // 上传开始
    var subscription = observable.subscribe(observer);
  })
};*/
