import Ls from "../../utils/token"
let common={
  //获取query
  getQueryVariable(queryName) {

    try{
      var vars = decodeURI(window.location.hash).split("?")[1].split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == queryName) { return pair[1]; }
      }
    }catch(e) {
      return null;
    }
    return null;
  }
}

export var courseSearchList={
  list:[
    {
      isType:2, //1 输入框 2 下拉框
      placeholder:"课程状态",
      downList:[
        {
          text:"value1",
          id:1
        }, {
          text:"value2",
          id:2
        }
      ]
    },
    {
      isType:1, //1 输入框 2 下拉框
      placeholder:"课程名称",
    }
  ]
}
export var courseResourceList={
  list:[
    {
      isType:2, //1 输入框 2 下拉框
      placeholder:"用户角色",
    },
    {
      isType:2, //1 输入框 2 下拉框
      placeholder:"在线状态",
    },
    {
      isType:2, //1 输入框 2 下拉框
      placeholder:"用户状态",
    },
    {
      isType:1, //1 输入框 2 下拉框
      placeholder:"用户手机号",
    }
  ]
}

//资源类型的类型
export var resourceTypeCheckboxType={
  list:[
    {
      id:1,
      name:"课程资源",
      isChecked:true
    },
    {
      id:2,
      name:"培训资源",
      isChecked:true
    },
    {
      id:4,
      name:"离线资源",
      isChecked:true
    }
  ]
}

