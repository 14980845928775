import http from '@/api/http'
// import qs from 'qs'
export * from '@/api/dApi'

//************************************/
//              系统模块              */
//************************************/

/*************************************/
/***************角色管理***************/
/*************************************/

/**
 * 获取全部角色
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getRoleAll (data) {
  return http({
    method: 'post',
    url: '/admin/role/getAll',
    data
  })
}

/**
 * 获取角色列表
 * @param data 角色数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getRoleList (data) {
  return http({
    method: 'post',
    url: '/admin/role/getList',
    data
  })
}

/**
 * 添加角色
 * @param data 角色数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addRole(data){
  return http({
      method: 'post',
      url: '/admin/role/addRole',
      data
  })
}

/**
 * 修改角色
 * @param data 角色数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editRole(data){
  return http({
    method: 'post',
    url: '/admin/role/editRole',
    data
  })
}

/**
 * 修改角色状态
 * @param data 角色数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeRoleState(data){
  return http({
    method: 'post',
    url: '/admin/role/changeState',
    data
  })
}

/**
 * 删除角色
 * @param data 角色数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteRole(data){
  return http({
    method: 'post',
    url: '/admin/role/deleteRole',
    data
  })
}


/**
 * 获取所有角色权限列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getAllRolePermissionList (data) {
  return http({
    method: 'post',
    url: '/admin/functiongroup/getChildren',
    data
  })
}

/**
 * 获取选中角色权限列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function getRolePermissionList (data) {
  return http({
    method: 'post',
    url: '/admin/roleright/getList',
    data
  })
}


/**
 * 更新权限-角色权限列表
 * @param data
 * @returns jsonData: 成功：1 失败：0
 */
export function setRoleRight (data) {
  return http({
    method: 'post',
    url: '/admin/roleright/setRoleRight',
    data
  })
}

/*************************************/
/**************功能组管理**************/
/*************************************/
/**
 * 获取全部功能组
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getFunctionGroupAll (data) {
  return http({
    method: 'post',
    url: '/admin/functiongroup/getAll',
    data
  })
}

/**
 * 获取功能组列表
 * @param data 功能组数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getFunctionGroupList (data) {
  return http({
    method: 'post',
    url: '/admin/functiongroup/getList',
    data
  })
}

/**
 * 添加功能组
 * @param data 功能组数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addFunctionGroup(data){
  return http({
      method: 'post',
      url: '/admin/functiongroup/addFunctionGroup',
      data
  })
}

/**
 * 修改功能组
 * @param data 功能组数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editFunctionGroup(data){
  return http({
    method: 'post',
    url: '/admin/functiongroup/editFunctionGroup',
    data
  })
}

/**
 * 修改功能组状态
 * @param data 功能组数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeFunctionGroupState(data){
  return http({
    method: 'post',
    url: '/admin/functiongroup/changeState',
    data
  })
}

/**
 * 删除功能组
 * @param data 功能组数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteFunctionGroup(data){
  return http({
    method: 'post',
    url: '/admin/functiongroup/deleteFunctionGroup',
    data
  })
}

/*************************************/
/***************功能管理***************/
/*************************************/

/**
 * 获取全部功能
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getFunctionAll (data) {
  return http({
    method: 'post',
    url: '/admin/function/getAll',
    data
  })
}

/**
 * 获取功能列表
 * @param data 功能数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getFunctionList (data) {
  return http({
    method: 'post',
    url: '/admin/function/getList',
    data
  })
}

/**
 * 添加功能
 * @param data 功能数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addFunction(data){
  return http({
      method: 'post',
      url: '/admin/function/addFunction',
      data
  })
}

/**
 * 修改功能
 * @param data 功能数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editFunction(data){
  return http({
    method: 'post',
    url: '/admin/function/editFunction',
    data
  })
}

/**
 * 修改功能状态
 * @param data 功能数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeFunctionState(data){
  return http({
    method: 'post',
    url: '/admin/function/changeState',
    data
  })
}

/**
 * 删除功能
 * @param data 功能数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteFunction(data){
  return http({
    method: 'post',
    url: '/admin/function/deleteFunction',
    data
  })
}


/*************************************/
/**********标准课程结构层级管理*********/
/*************************************/

/**
 * 获取全部标准课程结构层级
 * @param data 用户数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getStdLevelAll (data) {
  return http({
    method: 'post',
    url: '/admin/stdlevel/getAll',
    data
  })
}

/**
 * 获取标准课程结构层级列表
 * @param data 标准课程结构层级数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getStdLevelList (data) {
  return http({
    method: 'post',
    url: '/admin/stdlevel/getList',
    data
  })
}

/**
 * 添加标准课程结构层级
 * @param data 标准课程结构层级数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addStdLevel(data){
  return http({
      method: 'post',
      url: '/admin/stdlevel/addStdLevel',
      data
  })
}

/**
 * 修改标准课程结构层级
 * @param data 标准课程结构层级数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editStdLevel(data){
  return http({
    method: 'post',
    url: '/admin/stdlevel/editStdLevel',
    data
  })
}

/**
 * 修改标准课程结构层级状态
 * @param data 标准课程结构层级数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeStdLevelState(data){
  return http({
    method: 'post',
    url: '/admin/stdlevel/changeState',
    data
  })
}

/**
 * 删除标准课程结构层级
 * @param data 标准课程结构层级数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteStdLevel(data){
  return http({
    method: 'post',
    url: '/admin/stdlevel/deleteStdLevel',
    data
  })
}

/*************************************/
/***************日志管理***************
/*************************************/

/**
 * 获取日志列表
 * @param data 日志数据
 * @returns jsonData: 成功：1 失败：0
 */
 export function getLogList (data) {
  return http({
    method: 'post',
    url: '/admin/log/getList',
    data
  })
}

/**
 * 添加日志 [UNUSED]
 * @param data 日志数据
 * @returns jsonData: 成功：1 失败：0
 */
export function addLog(data){
  return http({
      method: 'post',
      url: '/admin/log/addLog',
      data
  })
}

/**
 * 修改日志 [UNUSED]
 * @param data 日志数据
 * @returns jsonData: 成功：1 失败：0
 */
export function editLog(data){
  return http({
    method: 'post',
    url: '/admin/log/editLog',
    data
  })
}

/**
 * 修改日志状态 [UNUSED]
 * @param data 日志数据
 * @returns jsonData: 成功：1 失败：0
 */
export function changeLogState(data){
  return http({
    method: 'post',
    url: '/admin/log/changeState',
    data
  })
}

/**
 * 删除日志
 * @param data 日志数据
 * @returns jsonData: 成功：1 失败：0
 */
export function deleteLog(data){
  return http({
    method: 'post',
    url: '/admin/log/deleteLog',
    data
  })
}
