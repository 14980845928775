import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_bar = _resolveComponent("header-bar");
  var _component_router_view = _resolveComponent("router-view");
  var _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createElementBlock("section", null, [_createVNode(_component_el_config_provider, {
    locale: $setup.locale,
    include: "GjTable"
  }, {
    default: _withCtx(function () {
      return [_ctx.$route.path.indexOf('/login') === -1 && _ctx.$route.path.indexOf('/view/editor') === -1 ? (_openBlock(), _createBlock(_component_header_bar, {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(_component_router_view)];
    }),
    _: 1
  }, 8, ["locale"])]);
}