import _defineProperty from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
// 引入vue方法
import { ElConfigProvider } from 'element-plus';
// 中文包
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
// 引入自定义方法
import { qn_token_get } from './api/qiniuApI';
// 引入自定义组件
export default {
  name: 'ZhProvider',
  components: _defineProperty({}, ElConfigProvider.name, ElConfigProvider),
  setup: function setup() {
    var locale = zhCn;
    if (localStorage.getItem("nof_userInfo")) {
      //获取七牛token
      qn_token_get().then(function (res) {});
    }
    return {
      locale: locale
    };
  }
};