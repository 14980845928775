import { ElMessage } from 'element-plus'
import 'element-plus/theme-chalk/el-message.css';

export default {
  error:(text, duration = 3000)=>{
    ElMessage({
      message: text,
      type: 'error',
    })
  },
  success:(text, duration = 3000)=>{
    ElMessage({
      message: text,
      type: 'success',
    })
  },
  warning:(text, duration = 3000)=>{
    ElMessage({
      message: text,
      type: 'warning',
    })
  }
}
