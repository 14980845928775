import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "FilterBar",
  props: ["filter"],
  methods: {
    searchSonFun: function searchSonFun() {
      this.$emit('searchFun', this.filter);
    },
    reloadSonFun: function reloadSonFun() {
      this.$emit('reloadFun');
    }
  }
};