export default {
  setToken (tokenKey, token) {
    return localStorage.setItem(tokenKey, token)
  },
   getToken (tokenKey) {
    return localStorage.getItem(tokenKey)
  },
  getParseToken(tokenKey){
    return JSON.parse(localStorage.getItem(tokenKey)) || {}
  },
  removeToken (tokenKey) {
    return localStorage.removeItem(tokenKey)
  }
}
