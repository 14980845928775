import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import service from './api/service'
import Ls from './utils/token'
import './assets/css/common.css'

// import 'default-passive-events'

import infiniteScroll from 'vue-infinite-scroll'
// import Toast from './components/mytoast/index.js'
import toast from './assets/js/toast'
import Common from './assets/js/common.js'

import PDFPreview from "pdf-preview-vue3";
import 'pdf-preview-vue3/style.css'

import tool from "./utils/tool";

// import ElementPlus from 'element-plus'
// import 'element-plus/theme-chalk/index.css'; //引用 element-ui 样式
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import vConsole from '@/assets/js/vconsole.js'

const app = createApp(App)
app.config.globalProperties.$toast = toast
app.config.globalProperties.$common = Common
app.config.globalProperties.$http = axios
app.config.globalProperties.service = service
app.config.globalProperties.Ls = Ls
// vue3 基础组件的自动化全局注册
const requireComponent = require.context(
  // 其组件目录的相对路径
  './components/bar',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /\.vue$/
)

requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)

  // 全局注册组件
  app.component(
    componentConfig.default.name,
    // 如果这个组件选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    componentConfig.default || componentConfig
  )
})

app.use(store)
  .use(tool)
  .use(router)
  .use(infiniteScroll)
  .use(PDFPreview).mount('#app')


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
