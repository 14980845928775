// ************此文件用于封装axios************
// 引入axios
import axios from 'axios';
//引入localstorge封装
import Ls from '@/utils/token'
// 引入qs模块，用来序列化post类型的数据
import { ElLoading } from 'element-plus';
import '@/router/index'
import router from "../router";
import 'element-plus/es/components/loading/style/css'
import Toast from '../components/mytoast/index.js'
/*
* 兼容多个ajax的请求进度条提前消失的问题
* */
var num = 0;

let Message = {
  error:(text)=>{
   // Toast.error(text)
  },
  success:(text)=>{
    Toast.success(text)
  }
};


import proEnv from '../../config/pro.env';  // 生产环境
import uatEnv from '../../config/uat.env';  // 生产环境
let baseUrl='';
// 环境的切换，环境可能有开发环境、测试环境和生产环境
if (process.env.NODE_ENV == 'development') {
  baseUrl = '/api';
}else if(process.env.NODE_ENV == 'production'){
  baseUrl = process.env.VUE_APP_BASE_API
}
/*else if (process.env.NODE_ENV == 'debug') {
  axios.defaults.baseURLCourse = 'http://192.168.0.200:9025/';
  axios.defaults.baseURLTeach = 'http://192.168.0.200:9026/';
  axios.defaults.baseURL = 'http://webapi.bricube.o-star.com.cn/';
}
else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURLCourse = 'http://webapi.bricube.o-star.com.cn/';
  axios.defaults.baseURLTeach = 'http://webapi.bricube.o-star.com.cn/';
  axios.defaults.baseURL = 'http://webapi.bricube.o-star.com.cn/';
}*/

//通过axios.defaults.timeout设置默认的请求超时时间。例如超过了10s，就会告知用户当前请求超时，请刷新等。
axios.defaults.timeout = 50000;
//post请求头的设置post请求的时候，我们需要加上一个请求头，所以可以在这里进行一个默认的设置，即设置post的请求头为application/x-www-form-urlencoded;charset=UTF-8
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';


window.loading=null;
function startLoading() {
  window.loading=ElLoading.service({
    lock:true,
    text:"请稍后...",
    background:'rgba(0,0,0,0.3)'
  })
}
function endLoading() {
  window.loading.close();
}
//请求拦截器===我们在发送请求前可以进行一个请求的拦截，为什么要拦截呢，我们拦截请求是用来做什么的呢？比如，有些请求是需要用户登录之后才能访问的，或者post请求的时候，我们需要序列化我们提交的数据。这时候，我们可以在请求被发送之前进行一个拦截，从而进行我们想要的操作。
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断

    let load = JSON.parse(JSON.stringify(config)).data.loadState;

    if(load && parseInt(load) === 1){
    }else{
      num++;
      startLoading();
    }

    if (Ls.getToken('nof_token')) {
      config.headers.Authorization = Ls.getToken('nof_token');
    }
    return config;
  },
  error => {
    return Promise.error(error);
  })

//响应的拦截======响应拦截器很好理解，就是服务器返回给我们的数据，我们在拿到之前可以对他进行一些处理。
const showState=(status) =>{
  let message=""
  switch (status){
    case 400:
      message="请求错误（400）"
      break
    case 401:
      message="未授权，请重新登录(401)"
      break
    case 403:
      message="拒绝访问(403)"
      break
    case 404:
      message="请求出错(404)"
      break
    case 408:
      message="请求超时(408)"
      break
    case 500:
      message="服务器错误(500)"
      break
    case 501:
      message="服务未实现(501)"
      break
    case 502:
      message="网络错误(502)"
      break
    case 503:
      message="服务不可用(503)"
      break
    case 504:
      message="网络超时(504)"
      break
    case 505:
      message="HTTP版本不受支持(505)"
      break
    default:
      message = `连接出错(${status})!`
  }
  return `${message}，请检查网络或联系管理员！`
}
axios.interceptors.response.use(
  //请求成功
  response => {
    /*兼容多个axios并发请求加载进度提前消失的问题*/
    num--;
    if (num <= 0) {
      endLoading();
    } else {
      startLoading();
    }




    // const status=response.code;
    // let msg="";
    //
    // if(status<200 || status>=300){
    //   msg=showState(status)
    //   if(typeof response.data === "string"){
    //     response.data={msg}
    //   }else{
    //     response.data.msg=msg
    //   }
    // }
    //
    // console.log(response)

    return response

  },
  // 请求失败：服务器状态码不是2开头的的情况，跟后台开发人员协商好统一的错误状态码，然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等，需求可自行扩展
  error => {
    num--;
    if (num <= 0) {
      endLoading();
    } else {
      startLoading();
    }
    const sus=error.response.status;

    if(sus == 401){
      Message.error('您的登录已经过期，请重新登录～');
      router.push('/login')
      return;
    }else{
      return Promise.resolve(error.response.data)
    }

  }

);


const http = options => {

  return  new Promise((resolve,reject) =>{
    const defaultOptions ={};
    const newOptions={
      ...defaultOptions,
      ...options
    };

    //headers默认传递json格式数据，这里也可以设置token，每次调用都会携带
    newOptions.headers = {
      'content-Type': 'application/json;charset=UTF-8',
      ...newOptions.headers
    };

    // console.log(newOptions.headers)
    //判断是否params需要传递机构id,即是否需要orgId；isOrgId=true需要,false不需要
    if(newOptions.isOrgId){
      newOptions.params={
        'orgId': Ls.getToken('orgId') || 0,
        ...newOptions.params
      }
    }else{
      newOptions.params={
        ...newOptions.params
      }
    }
    //请求路径，临时兼容多个
    let httpUrl="";
    if(newOptions.url.indexOf("http")>-1){
      httpUrl=`${newOptions.url}`;
    }else{
      httpUrl=`${baseUrl}${newOptions.url}`;
    }
    axios({
      method:newOptions.method,
      url: httpUrl,
      params:newOptions.params || {},
      data:newOptions.data || {},
      headers:newOptions.headers
    }).then(res=>{

      // 后台过期：24h后过期，不同浏览器可以同时登录
      if(res.data.isLogout && res.data.isLogout === 1){
        Message.error("登录过期，请重新登录！");
        setTimeout(()=>{
          localStorage.clear();
          //执行退出
          window.location.href=window.location.origin + "/#/login";
        },2000);
        return;
      }
      //
      //根据返回的状态码判断，注意res返回的并不一定都是status，比如小程序就是statusCode
      if (res.status == 200) {
        //这里我们只需要获取返回的data中的数据即可
        resolve(res.data);
      } else {
        reject(res);
      }
    }).catch(err=>{
      // reject(err);
    })


  })
};
export default http



