let common={
  //获取query
  getQueryVariable(queryName) {

    try{
      var vars = decodeURI(window.location.hash).split("?")[1].split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == queryName) { return pair[1]; }
      }
    }catch(e) {
      return null;
    }
    return null;
  }
}

//教研类型
export var researchTypeData={
  'title':'教研类型',
  'type':1, // 1 下拉框 2多选 3文字输入 4日期时间 5日期时间段
  'message':"请选择教研类型",
  'data': {
    bgType: 'grey',
    wordColor: 'blue',
    typeStr: 'researchType',
    tips: '选择教研类型',
    data: common.getQueryVariable("researchTypeName") || '',
    id:common.getQueryVariable("researchTypeId") || '',
    des:{
      id:"researchTypeId",
      name:"researchTypeName"
    },
    list: [
      {
        researchTypeId: 1,
        researchTypeName: '入园教研'
      },
      {
        researchTypeId: 2,
        researchTypeName: '集中教研'
      },
      {
        researchTypeId:3,
        researchTypeName: '线上教研'
      }
    ]
  }
}
//教研阶段
export var ageGroup={
  title:'教研阶段',
  type: 2,
  message:"教研阶段至少选择一个哦",
  data: [
    { //多选数据
      id:1,
      checked:false,
      disabled:false,
      gardenName:"托"
    },
    { //多选数据
      id:2,
      checked:false,
      disabled:false,
      gardenName:"小"
    },
    { //多选数据
      id:3,
      checked:false,
      disabled:false,
      gardenName:"中"
    },
    { //多选数据
      id:4,
      checked:false,
      disabled:false,
      gardenName:"大"
    },
    { //多选数据
      id:5,
      checked:false,
      disabled:false,
      gardenName:"学前"
    }
  ]
}
//参加人数
export var peopleNum={
  title:'参加人数',
  inputType:"number",
  type: 3,
  data:"",
  message:"请输入参加人数",
  company:'人'
}
//服务开始时间
export var beginEndTime={
  type: 5,
  startTitle:'服务开始时间',
  startData:"",//2022-12-06 00:00:00
  endTitle:'服务结束时间',
  endData:'',
  message:"开始时间必须小于结束时间"
}
//教研形式
export var researchKindSelect={
  'title':'教研形式',
  'type':1, // 1 下拉框 2多选 3文字输入 4日期时间 5日期时间段
  'message':"请选择教研形式",
  'data': {
    bgType: 'grey',
    wordColor: 'blue',
    typeStr: 'researchKind',
    tips: '选择教研形式',
    data: common.getQueryVariable("researchKindName") || "",
    id:common.getQueryVariable("researchKindId") || "",
    des:{
      id:"researchKindId",
      name:"researchKindName"
    },
    list: [
      { //多选数据
        researchKindId:7,
        researchKindName:"线上集中教研"
      },
      { //多选数据
        researchKindId:8,
        researchKindName:"线下集中教研"
      }
    ]
  }
}
//教研形式多选
export var researchKindCheckbox= {
  title:'教研形式',
  type: 2,
  typeStr:"researchKind",
  message:"请选择教研形式",
  active:[],
  data: [   //教研形式名称 1：入园备课，2：入园听评课，3：家长工作，4：园长约谈，5：光和入场指导，6：其他，7：线上集中教研，8：线下集中教研
    { //多选数据
      id:1,
      checked:false,
      gardenName:"入园备课"
    },
    { //多选数据
      id:2,
      checked:false,
      gardenName:"入园听评课"
    },
    { //多选数据
      id:3,
      checked:false,
      gardenName:"家长工作"
    },
    { //多选数据
      id:4,
      checked:false,
      gardenName:"园长约谈"
    },
    { //多选数据
      id:5,
      checked:false,
      gardenName:"光和入场指导"
    },
    { //多选数据
      id:6,
      checked:false,
      gardenName:"其他"
    }
  ]
}
//活动主题名称
export var activitytitle={
  title:'活动主题名称',
  type: 3,
  data:'',
  message:`请输入活动主题名称`
  // company:'人'
}
