import * as detailData from './data.js'
import * as initData from './initData.js'
import Ls from "../../utils/token"

import Toast from '../../components/mytoast/index.js'
let Message = {
  error:(text)=>{
    Toast.error(text)
  },
  success:(text)=>{
    Toast.success(text)
  }
};

export default {
  mFormat(url){
    if(!url){
      return "";
    }
    // var url="www.com.cn/swf?t=1111&d=222";
    var arr=url.split('.');
    var str=arr[arr.length-1];
    if(str.indexOf("?")){
      str=str.split('?')[0];
    }
    return str;
  },
  //返回类型
  checkResourceType(url){
    if(url == ""){
      return ""
    }
    let arr=[{
      type:"image",
      list:[{
        ext:"jpg"
      },{
        ext:"jpeg"
      },{
        ext:"png"
      },{
        ext:"gif"
      }]
    },{
      type:"audio",
      list:[{
        ext:"mp3"
      },{
        ext:"ogg"
      }]
    },{
      type:"mp4",
      list:[{
        ext:"mp4"
      }]
    },{
      type:"pdf",
      list:[{
        ext:"pdf"
      }]
    },{
      type:"html",
      list:[{
        ext:"html"
      },{
        ext:"htm"
      }]
    }]
    for (let i = 0; i < arr.length; i++) {
      let list=arr[i].list;
      let type=arr[i].type;
      for (let j = 0; j < list.length; j++) {
        if(this.mFormat(url) == list[j].ext){
          return type;
        }
      }
    }
    return "other";
  },
  //拼接get参数
  mGetUrl:(url,data)=>{
    let str='';
    let i=0;
    for(let k in data){
      let value = data[k] !== undefined ? data[k] : '';
      str += `${i == 0 ? '' : '&'}${k}=${encodeURIComponent(value)}`;
      i++;
    }

    let nUrl = url;
    if(str != ''){
      nUrl = url += (url.indexOf('?') < 0 ? '?' : '') + str;
    }
    return nUrl;
  },
  //提示
  message:(str)=>{
    Message.success(str)
  },
  //克隆所有参数
  mRouteData(data){
    let json={};
    for(let item in data){
      json[item]=data[item];
    }
    return json;
  },
  //获取数组下表
  getIndex(arr,value,id){
    for (let i = 0; i < arr.length; i++) {
      if(arr[i][id] == value){
          return i;
      }
    }
    return  -1;
  },
  /**
   * [获取URL中的参数名及参数值的集合]
   * 示例URL:http://htmlJsTest/getrequest.html?uid=admin&rid=1&fid=2&name=小明
   * @param {[string]} urlStr [当该参数不为空的时候，则解析该url中的参数集合]
   * @return {[string]}       [参数集合]
   */
  GetRequest(urlStr) {
    if (typeof urlStr == "undefined") {
      var url = decodeURI(location.search); //获取url中"?"符后的字符串
    } else {
      var url = "?" + urlStr.split("?")[1];
    }
    // console.log(decodeURI(window.location.hash).split("?")[1].split("&"))
    return theRequest;
  },
  //弹框
  mPopup(json) {
    return new Promise(function(resolve, reject){
      var data={
        btnN:json.btnN || 2,
        title: json.title || '确定要退出吗？',
        content: json.content || '确定要退出吗？',
        cssClass: json.cssClass || '',
        cancelText: json.cancelText || '取消',
        cancelType: json.cancelType || 'cancel',
        okText: json.okText || '确定',
        okType: json.okType || 'confirm',
        closeType: json.closeType || false
      };


      let str="";
      if(data.btnN == 1){
        // console.log(data.btnN,"11111111111111")
        str= '      <div class="popup_box">\n' +
          '        <div class="popup_main">\n' +
          '          <div class="popup_close"></div>\n' +
          // '          <div class="popup_close" @click="Hidden()"><img src="../img/popup_close.png" alt=""></div>\n' +
          '          <div class="popup_head">\n' +
          '            <div class="">'+data.title+'</div>\n' +
          '\n' +
          '          </div>\n' +
          '          <div class="popup_content">\n' +
          '            <div class="popup_quitWord common_popup">'+data.content+'</div>\n' +
          '          </div>\n' +
          '          <div class="popup_foot">\n' +
          '            <ul class="clearfix">\n' +
          '              <li>\n' +
          '                <div class="popup_btn confirm">'+data.okText+'</div>\n' +
          '              </li>\n' +
          '              <li class="cancel_li">\n' +
          '                <div class="popup_btn cancel">'+data.cancelText+'</div>\n' +
          '              </li>\n' +
          '            </ul>\n' +
          '          </div>\n' +
          '        </div>\n' +
          '      </div>';
      }else{
        // console.log("22222222222222")
        str= '      <div class="popup_box">\n' +
          '        <div class="popup_main">\n' +
          '          <div class="popup_close"></div>\n' +
          // '          <div class="popup_close" @click="Hidden()"><img src="../img/popup_close.png" alt=""></div>\n' +
          '          <div class="popup_head">\n' +
          '            <div class="">'+data.title+'</div>\n' +
          '\n' +
          '          </div>\n' +
          '          <div class="popup_content">\n' +
          '            <div class="popup_quitWord common_popup">'+data.content+'</div>\n' +
          '          </div>\n' +
          '          <div class="popup_foot">\n' +
          '            <ul class="clearfix">\n' +
          '              <li class="cancel_li">\n' +
          '                <div class="popup_btn cancel">'+data.cancelText+'</div>\n' +
          '              </li>\n' +
          '              <li>\n' +
          '                <div class="popup_btn confirm">'+data.okText+'</div>\n' +
          '              </li>\n' +
          '            </ul>\n' +
          '          </div>\n' +
          '        </div>\n' +
          '      </div>';
      }


      var section=document.createElement("section");
      section.className="popup_mask";
      if(data.cssClass){
        section.className="popup_mask "+data.cssClass;
      }
      section.innerHTML=str;
      document.body.appendChild(section);



      setTimeout(function (){
        var aBtn= document.querySelectorAll(".popup_btn");
        var oBtnClose= document.querySelector(".popup_close");
        oBtnClose.addEventListener('click', function(event) {
          document.body.removeChild(section)
          // resolve(data.closeType)
        })
        for(let i =0; i<aBtn.length; i++){
          aBtn[i].addEventListener('click', function(event) {
            if(this.innerText == data.okText){
              document.body.removeChild(section)
              resolve(true)
            }else {
              document.body.removeChild(section)
              resolve(false)
            }
          })
        }
      },500)
    })
  },
  mGeTel(phone){
    if(!phone)return;
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return phone.replace(reg, "$1****$2");
  },
  isMine(nameTeacher,nameLogin){
    if(nameTeacher === nameLogin){
      return true;
    }else{
      return false;
    }
  },
  isMineId(idTeacher,idLogin){
    if(idTeacher === idLogin){
      return true;
    }else{
      return false;
    }
  },
  //弹框alert
  mAlert(json){
    return new Promise(function(resolve, reject){
      var data={
        title: json.title || '绑定提示',
        content: json.content || '微信已绑定过其他账号，绑定失败!',
        cssClass: json.cssClass || '',
        cancelText: json.cssClass || '取消',
        cancelType: json.cssClass || 'cancel',
        okText: json.okText || '确定',
        okType: json.okType || 'confirm'
      };

      let str='<section class="popup_mask">\n' +
          '                <div class="popup_box">\n' +
          '                <div class="popup_main" style=" padding-bottom: 50px; border-radius: 12px;">\n' +
          '                <div class="popup_close" style="display: none;">\n' +
          '\n' +
          '                </div> \n' +
          '                <div class="popup_head">\n' +
          '                <div>'+data.title+'</div>\n' +
          '                </div> <div class="popup_content" style="    padding: 0;\n' +
          '    display: flex;\n' +
          '    align-items: center;"><div class="popup_quitWord common_popup" style="width:100%; padding-top: 18px;">'+data.content+'</div>\n' +
          '            </div> \n' +
          '            <div class="popup_foot">\n' +
          '                <ul class="clearfix">\n' +
          '                <li>\n' +
          '                <div class="popup_btn confirm" style="position: relative;margin: 0 auto; top: 12px;">'+data.okText+'</div>\n' +
          '                </li>\n' +
          '                </ul>\n' +
          '                </div>\n' +
          '                </div>\n' +
          '                </div>\n' +
          '                </section>';

      var section=document.createElement("section");
      section.className="popup_mask";
      section.innerHTML=str;
      document.body.appendChild(section);


      setTimeout(function (){
        var aBtn= document.querySelectorAll(".popup_btn");
        var oBtnClose= document.querySelector(".popup_close");
        for(let i =0; i<aBtn.length; i++){
          aBtn[i].addEventListener('click', function(event) {
            if(this.innerText == data.okText){
              document.body.removeChild(section)
              resolve(true)
            }else {
              document.body.removeChild(section)
              resolve(false)
            }
          })
        }
        oBtnClose.addEventListener('click', function(event) {
          document.body.removeChild(section)
          resolve(false)
        })
      },500)
    })
  },
  //清除js里面的data.js数据恢复初始哈
  clearData(){
    Ls.removeToken("course");
    Ls.removeToken("gardens");
    for (let detailDataKey in detailData) {
        let detailVal=detailData[detailDataKey];
        // console.log(detailData[detailDataKey].title,detailData[detailDataKey])
        switch (detailVal.type){
          case 1: //单选
            delete  detailData[detailDataKey].active;
            detailData[detailDataKey].data.id='';
            detailData[detailDataKey].data.data='';
            break;
          case 2: //多选
            delete  detailData[detailDataKey].active;
            for (let i = 0; i < detailData[detailDataKey].data.length; i++) {
              detailData[detailDataKey].data[i].checked=false;
            }

            if(detailData[detailDataKey].explain){
              detailData[detailDataKey].explain.value="";
              detailData[detailDataKey].explain.checked=false;
              detailData[detailDataKey].explainText="";
            }
            break;
          case 3: //输入框
            detailData[detailDataKey].data='';
            break;
          case 4: //日期时间（暂时用不到）

            break;
          case 5: //选择时间段
            detailData[detailDataKey].startData='';
            detailData[detailDataKey].endData='';
            break;
          case 6: //选择园所
            break;
          case 7: //输入框
            detailData[detailDataKey].data='';
            break;
          case 8: //输入框
            detailData[detailDataKey].data='';
            break;
        }
    }
  },
  //判断数据是否发生改变
  isChange(oldJson,newJson){
    this.isChangeValue = false;
    //调用递归json比对方法
    // return this.getMenuAll(oldJson,newJson,this.isChangeValue)
    return this.getMenuAll(oldJson,newJson,this.isChangeValue)
    // alert(this.sum(3))
    // alert(this.getM(oldJson,newJson,this.isChangeValue))
  },
  //递归json进行比对
  getMenuAll(oldJson,newJson,isChangeValue){
    // console.log(oldJson,newJson,isChangeValue)
    //递归返回未完成
    if(isChangeValue == true){
      return true;
    }
    if(typeof oldJson  != typeof newJson){
      console.log("旧值和新值",oldJson,newJson)
      // return false;
      return true;
    }
    //以新的newJson为准
    if(newJson instanceof Array){
      if(oldJson == undefined){
        oldJson=[];
      }
      if(newJson == undefined){
        newJson=[];
      }
        if(oldJson.length == newJson.length){
          //数组
          for (let i = 0; i < newJson.length; i++) {
            if(this.getMenuAll(oldJson[i],newJson[i],isChangeValue)){
              return true;
              break;
            }
          }
        }else{
          return true;
        }

    }else if(this.isJson(newJson)){
      for(let key in newJson){
        if(this.getMenuAll(oldJson[key],newJson[key],isChangeValue)){
          return true;
          break;
        }
      }

    }else {
      if(oldJson == undefined){
        oldJson = "";
      }
      if(newJson == undefined){
        newJson = "";
      }

      // console.log(newJson,oldJson)

        //基础类型
        if(newJson != oldJson){
          isChangeValue=true;
          // console.log("有改动")
          // return this.getMenuAll(1,2,isChangeValue)
          return isChangeValue;
        }
    }

  },
   isObjEqual(obj1, obj2) {
    //  Object.getOwnPropertyNames 返回对象所有的属性或对象中属性的个数
    //  打印为: ["name", "val", "pop", "age"]
    let aProps = Object.getOwnPropertyNames(obj1);
    let bProps = Object.getOwnPropertyNames(obj2);

    //先判断两个数据length
    if (aProps.length == bProps.length) {
      //循环拿到数值进行对比
      aProps.forEach(e => {
        if (obj1[e] !== obj2[e]) {
          console.log('数据改变')
        } else {
          console.log('数据不变')
          return false;
        }
      });
    } else {
      return false
    }
  },
  //判断是否是json
  isJson(obj){
    var  isjson = typeof (obj) == "object"  && Object.prototype.toString.call(obj).toLowerCase() == "[object object]"  && !obj.length;
    return  isjson;
  },
  isEditFun(nowTime,localTime){
    // console.log(nowTime,localTime)

    var date=new Date(nowTime*1000);
    var year=date.getFullYear();
    var month=date.getMonth()+1;

    var localTimeArry=localTime.split('/');
    var localMonthArry=localTimeArry[1].split('-');
    var localYear=parseInt(localTimeArry[0]);
    var localStartMonth=parseInt(localMonthArry[0]);
    var localEndMonth=parseInt(localMonthArry[1]);

    // console.log(year)
    // console.log(localYear)

    var isEdit;
    if(localStartMonth === 1 && localEndMonth===6){
     // 缓存2022/1-6，当前年份2022
      if(localYear > year){
        // 当前2021 本地缓存存储年份大于当下年份
        isEdit = false;
      }else if(localYear === year){
        // 当前2022  本地缓存存储年份等于当下年份
        if(month >= 1 && month <= 9){
          isEdit = true;
        }else{
          isEdit = false;
        }
      }else{
        // 当前2023 本地缓存存储年份小于于当下年份
        isEdit = false;
      }
    }else if(localStartMonth === 7 && localEndMonth===12){
      // 缓存2022/7-12，当前年份2022
      if(localYear > year){
        // year2021 本地缓存存储年份小于当下年份
        isEdit = false;
      }else if(localYear === year){
        // 本地2022  本地缓存存储年份等于当下年份
        if(month >= 7 && month <= 12){
          isEdit = true;
        }else{
          isEdit = false;
        }
      }else{
        // 本地2023 本地缓存存储年份大于当下年份
        if(month>=1 && month<=3){
          isEdit = true;
        }else{
          isEdit = false;
        }
      }
    }else{
      isEdit = false;
    }

    // console.log(isEdit)
    return isEdit;

  },



  // 检查手机号
  checkPhone: function (phone) {

    var self = this,
      status = false;

    if (!phone) {
      Message.error("请输入手机号");
      status = true;
      return status;
    }

    var phone = parseInt(phone, 0);

    if (!/^1\d{10}$/g.test(phone)) {

      Message.error("手机号码格式不合法");
      status = true;
      return status;
    }

    return status;
  },
  getUrlName(url){
    let arr=url.split("/")
    return arr[arr.length -1].split(".")[0];
  },
  //获取名称/Id
  getName:function (id,arr){
    let nowName;
    for(let i=0;i<arr.length;i++){
      if(id === arr[i].id){
        nowName = arr[i].name
        break;
      }else{
        nowName =''
      }
    }
    return nowName;
  },
  getId:function (name,arr){
    let nowId;
    for(let i=0;i<arr.length;i++){
      if(name === arr[i].name){
        nowId = arr[i].id
        break;
      }else{
        nowId =''
      }
    }
    return nowId;
  },

  // 检查是否有筛选数据
  checkSearchOrAllFilter:function (array){
    for(let i=0;i<array.length;i++){
      if(array[i].value !== ''){
        return true
      }
    }
    return false
  },
  //深度克隆
  deepClone:function (obj){
    if(Array.isArray(obj)) {
      let arr = []
      for (let index = 0; index < obj.length; index++) {
        const element = obj[index];
        arr[index] = Object.assign({}, element)
      }
      return arr
    } else {
      return Object.assign({}, obj)
    }
  },
}
