import * as httpRequest from '@/api/dApi'
import Toast from '../components/mytoast/index.js'
import Ls from "../utils/token"

let Message = {
  error:(text)=>{
    // Toast.error(text)
  },
  success:(text)=>{
    Toast.success(text)
  }
};


// console.log(httpRequest)
export default {
  // 发送http请求
  axiosUtils: (json) => {
    /* json={
        requestName,
        data,
        sucFn,
        errFn,
        isMessage
    }; */

    let requestName = ''
    if (json.requestName && json.requestName !== '') {
      requestName = json.requestName
    } else {
      return
    }



    //添加userId+roleId
    if(!json.data.userId){
      json.data.userId=Ls.getParseToken('nof_userInfo').userId;
      json.data.roleId=Ls.getParseToken('nof_userInfo').roleId;
    }

    httpRequest[requestName](json.data).then(res => {
      if (json.isMessage === 1) {
        Message.success(res.message)
      }
      json.sucFn && json.sucFn(res)
    }).catch(err => {
      json.errFn && json.errFn(err)
      Message.error(err.message)
    })
  }
}
